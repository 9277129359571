<template>
  <v-app id="inspire" class="background">
    <v-main class="background">
      <v-toolbar color="transparent" elevation="0">
        <v-progress-linear
          v-if="loading"
          indeterminate
          absolute

          bottom
          color="orange accent-1"
        />
        <v-img
          src="@/assets/web-logo.png"
          max-height="150"
          max-width="200"
          class="ma-5"
        />
        <v-spacer />
        <span style="font-size: 14px; margin-left: 5px; margin-top: 10px;" class="white--text text-caption">v.2.2.0-14224</span>
      </v-toolbar>
      <v-container
        class="background"
        fluid
        fill-height
      >
        <v-alert
          v-if="logoutMessage || error"
          style="position: absolute; bottom: 15px; left: 50%; transform: translate(-50%, 50%); z-index: 2;"
          dismissible
          dark
          color="red darken-2"
          border="top"
        >
          {{ logoutMessage }} {{ error }}
        </v-alert>
        <v-layout align-center justify-center>
          <v-flex
            xs12
            sm6
            md4
            xl3
          >
            <!-- <v-img
              src="https://homevision-help-center-content.s3.eu-north-1.amazonaws.com/homevision.png"
              max-height="150"
              class="ma-5"
            /> -->
            <v-slide-x-transition mode="out-in">
              <v-card
                v-if="selectedView === '0'"
                key="1"
                :loading="loading"
                class="elevation-0"
                color="transparent"
              >
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="orange lighten-4 mt-1"
                    elevation="0"
                    large
                    block
                    @click="selectedView = '1'"
                  >
                    Login
                  </v-btn>
                </v-card-actions>
              </v-card>
              <v-card
                v-if="selectedView === '1'"
                key="2"
                :loading="loading"
                class="elevation-0"
                color="transparent"
              >
                <v-card-text class="pl-5 pr-5 pb-1 text-center">
                  <v-form v-if="!loading">
                    <v-text-field
                      v-model="email"
                      solo
                      autocomplete="username"
                      color="orange lighten-1"
                      for="email"
                      name="login"
                      dark
                      label="Email"
                      type="email"
                      required
                    />
                  </v-form>
                  <v-progress-circular
                    v-else
                    :size="50"
                    color="orange accent-1"
                    indeterminate
                  />
                </v-card-text>
                <v-card-actions class="pl-5 pr-5 pb-3">
                  <v-spacer />
                  <div style="height: 40px;">
                    <v-btn
                      v-if="email && !loading"
                      color="orange lighten-4"
                      @click="send2FACode"
                    >
                      Login
                    </v-btn>
                  </div>
                </v-card-actions>
              </v-card>
              <v-card
                v-if="selectedView === '2'"
                key="3"
                :loading="loading"
                class="elevation-0"
                color="transparent"
              >
                <v-card-text class="pl-5 pr-5 pb-1 text-center">
                  <v-form v-if="!loading">
                    <v-text-field
                      id="token"
                      v-model="entered2FACode"
                      solo
                      autocomplete="token"
                      name="token"
                      dark
                      color="orange lighten-1"
                      label="Enter the code"
                      type="text"
                      required
                    />
                  </v-form>
                  <v-progress-circular
                    v-else
                    :size="50"
                    color="orange accent-1"
                    indeterminate
                  />
                </v-card-text>
                <v-card-actions class="pl-5 pr-5 pb-3">
                  <v-spacer />
                  <div style="height: 40px;">
                    <v-btn
                      v-if="email && entered2FACode && !loading"
                      color="orange lighten-4"
                      elevation="0"
                      @click="check2FACode"
                    >
                      Continue
                    </v-btn>
                  </div>
                </v-card-actions>
              </v-card>
              <v-card
                v-if="selectedView === '3'"
                key="4"
                :loading="loading"
                class="elevation-0"
                color="transparent"
              >
                <v-card-text class="pl-5 pr-5 pb-1 text-center">
                  <v-form v-if="!loading">
                    <v-text-field
                      id="password"
                      v-model="password"
                      solo
                      dark
                      for="password"
                      color="orange lighten-1"
                      name="password"
                      label="Password"
                      type="password"
                      required
                    />
                  </v-form>
                  <v-progress-circular
                    v-else
                    :size="50"
                    color="orange accent-1"
                    indeterminate
                  />
                </v-card-text>
                <v-card-actions class="pl-5 pr-5 pb-3">
                  <v-spacer />
                  <v-btn
                    v-if="email && password && entered2FACode && !loading"
                    color="orange lighten-4"
                    elevation="0"
                    @click="handleLogin"
                  >
                    Continue
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-slide-x-transition>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'Login',
  data: () => ({
    email: '',
    password: '',
    entered2FACode: '',
    loading: false,
    error: '',
    selectedView: '0'
  }),
  computed: {
    logoutMessage () {
      return this.$store.state.logoutMessage
    }
  },
  methods: {
    send2FACode () {
      this.loading = true
      this.$store.dispatch('check2FACode', { type: 'issue', email: this.email })
        .then(() => {
          this.loading = false
          this.selectedView = '2'
        })
        .catch((error) => {
          this.email = ''
          this.password = ''
          this.loading = false
          this.error = 'Not authorized to access this site.'
          throw (error)
        })
    },
    check2FACode () {
      this.loading = true
      this.$store.dispatch('check2FACode', { type: 'verify', code: this.entered2FACode })
        .then(() => {
          this.loading = false
          this.selectedView = '3'
        })
        .catch((error) => {
          this.email = ''
          this.password = ''
          this.entered2FACode = ''
          this.selectedView = '1'
          this.loading = false
          this.error = 'Not authorized to access this site.'
          throw (error)
        })
    },
    async handleLogin () {
      if (this.email && this.password) {
        this.loading = true
        await this.$store.dispatch('login', {
          email: this.email,
          password: this.password,
          code: this.entered2FACode
        })
          .then(response => {
            if (response) {
              this.email = ''
              this.password = ''
              this.$router.replace('/')
              this.loading = false
            }
          })
          .catch(error => {
            this.email = ''
            this.password = ''
            this.entered2FACode = ''
            this.selectedView = '1'
            this.loading = false
            this.error = 'Not authorized to access this site.'
            throw (error)
          })
      }
    }
  }
}
</script>

<style>

.background {
  background: #2c3036;
}

</style>
